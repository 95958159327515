.bodyGeneral {
  grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 2;
  width: 100%;
  min-height: 118vw;
  background-image: url("../../imgs/fondoCuerpo.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: gainsboro;
  border-radius: 10px;
  border: solid 1px black;
  position: relative;
  padding-bottom: 10%;
}

.firstText {
  width: 50%;
  margin: 7% 0 0 5%;
  background-image: url("../../imgs/iconText.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.firstText img {
  width: 90%;
}

.firstText p {
  font-size: 120%;
  font-weight: 500;
  text-align: justify;
  line-height: 35px;
  color: #0f2345;
}

.bubblesImg {
  display: flex;
  gap: 20%;
  margin: 5% auto;
  justify-content: flex-start;
  padding-left: 10%;
}

.bubblesImg span {
  width: 11vw;
  height: 11vw;
  background-color: gainsboro;
  display: block;
  border-radius: 100%;
  border: 0.5em solid #0f2345;
  background-size: 140%;
  background-repeat: no-repeat;
  background-position: center;
}

.img1 {
  background-image: url("../../imgs/clubes_equipos.png");
}

.img2 {
  background-image: url("../../imgs/empresariales.png");
}

.img3 {
  background-image: url("../../imgs/compras.png");
}

.img4 {
  background-image: url("../../imgs/baldonis.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bubblesContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titleBubble {
  margin: 8% 0 2% 0;
  font-size: 130%;
  font-weight: 900;
  color: #0f2345;
}

.textBubble {
  text-align: center;
  margin: 0;
  font-size: 130%;
  font-weight: 900;
  color: #0f2345;
}

.secondText {
  display: flex;
  margin: 10% 0 10% 0;
  align-items: flex-end;
}

.secondText span {
  width: 28vw;
  height: 28vw;
  background-color: gainsboro;
  display: block;
  border-radius: 100%;
  border: 0.5em solid white;
}

.containerImg-secondText {
  width: 50%;
  padding-top: 3%;
}

.second-text {
  width: 45%;
  background-image: url("../../imgs/iconText.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.second-text img {
  width: 95%;
}

.second-text p {
  font-size: 120%;
  font-weight: 500;
  text-align: justify;
  line-height: 40px;
  color: #0f2345;
}

.weAre {
  right: 10px;
  position: absolute;
  font-weight: 900;
  font-style: italic;
  color: #d63128;
  font-size: 2vw;
  bottom: 0;
  line-height: 45%;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .bodyGeneral {
    grid-area: row-start 4 / col-start 1 / row-end 4 / col-end 2;
    min-height: 200vw;
    background-position: center;
    background-size: cover;
    padding-bottom: 25%;
  }

  .firstText {
    width: 65%;
    margin: 17% 0 0 5%;
    background-position: center;
    background-size: 100%;
  }

  .firstText img {
    width: 75%;
  }

  .firstText p {
    font-size: 3vw;
    line-height: 120%;
  }

  .secondText {
    margin-top: 20%;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .secondText span {
    width: 32vw;
    height: 32vw;
    border: 0.5em solid white;
  }

  .second-text {
    background-size: 85%;
    background-position: center;
    width: 55%;
  }

  .second-text img {
    width: 90%;
  }

  .second-text p {
    font-size: 3vw;
    line-height: 130%;
  }

  .bubblesImg {
    margin: 18% 0 0 0;
    width: 100%;
    justify-content: space-around;
    padding-left: 0;
  }

  .bubblesImg span {
    width: 15vw;
    height: 15vw;
    border: 1.5vw solid #0f2345;
  }

  .bubblesImg p {
    font-size: 2.5vw;
  }

  .bubblesContainer {
    width: 35%;
  }

  .weAre {
    left: 2vw;
    font-size: 3vw;
  }
}
