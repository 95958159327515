.footer {
  grid-area: row-start 4 / col-start 1 / row-end 4 / col-end 2;
  background-color: gainsboro;
  width: auto;
  border-radius: 10px;
  border: solid 1px black;
  display: grid;
  grid-template-columns: repeat(20, [col-start] auto [col-end]);
  grid-template-rows: repeat(2, [row-start] auto [row-end]);
  gap: 1%;
  color: white;
  padding: 2%;
}

.rrss {
  grid-area: row-start 1 / col-start 1 / row-end 2 / col-end 1;
  background-color: #0f2345;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
  padding: 0.5em;
}

.rrss a {
  text-align: center;
}

.rrss img {
  width: 75%;
}

.informacion {
  grid-area: row-start 1 / col-start 2 / row-end 1 / col-end 9;
  background-color: #0f2345;
  padding: 3%;
  border-radius: 30px;
  display: flex;
}

.qrData {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.qrData span {
  width: 40%;
}

.qrData img {
  width: 100%;
}

.numeroPermiso {
  font-size: 70%;
}

.spanInfo {
  width: 50%;
}

.formulario {
  grid-area: row-start 1 / col-start 10 / row-end 1 / col-end 20;
  background-color: #0f2345;
  display: flex;
  flex-direction: column;
  padding: 2.5%;
  border-radius: 30px;
  justify-content: space-between;
}

.inputsDatos {
  display: flex;
  width: 100%;
  gap: 1em;
}

.combo1 {
  width: 30%;
}

.combo2 {
  width: 30%;
}

.formulario input {
  border-radius: 20px;
  height: 1.5vw;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 0.5em;
  margin-bottom: 1em;
}

.titleForm {
  grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 3;
  margin: 0;
}

.titleForm2 {
  margin-top: 0;
}

.inputNombre {
  grid-area: row-start 2 / col-start 1 / row-end 2 / col-end 1;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  font-size: 0.8vw;
  justify-content: space-around;
}

.inputEmail {
  grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 1;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  font-size: 0.8vw;
  justify-content: space-around;
}

.inputNumero {
  grid-area: row-start 2 / col-start 2 / row-end 2 / col-end 2;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  font-size: 0.8vw;
  justify-content: space-around;
}

.inputCiudad {
  grid-area: row-start 3 / col-start 2 / row-end 3 / col-end 2;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  font-size: 0.8vw;
  justify-content: space-around;
}

.textArea {
  grid-area: row-start 2 / col-start 3 / row-end 3 / col-end 3;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  font-size: 0.8vw;
  justify-content: space-around;
  width: 30%;
}

.textArea textarea {
  height: 65%;
  width: 100%;
  border-radius: 10px;
  height: 5.5vw;
  background-color: rgba(255, 255, 255, 0.5);
  padding-left: 1em;
  padding-right: 1em;
  resize: none;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 #f5f5f5;
  margin-bottom: 1em;
}

textarea::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 3px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

textarea::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.documentacion {
  grid-area: row-start 2 / col-start 2 / row-end 2 / col-end 20;
  background-color: #0f2345;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
  margin-top: 1%;
  font-weight: 100;
  text-align: center;
  font-size: 70%;
}

.documentacion a {
  color: white;
  text-decoration: none;
}

.buttonSend {
  width: 30%;
  cursor: pointer;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 768px) {
  .footer {
    grid-area: row-start 5 / col-start 1 / row-end 5 / col-end 2;
    display: grid;
    grid-template-columns: repeat(1, [col-start] auto [col-end]);
    grid-template-rows: repeat(4, [row-start] auto [row-end]);
    gap: 1%;
    padding-bottom: 5%;
  }

  .rrss {
    grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 1;
    flex-direction: row;
  }

  .rrss img {
    width: 60%;
  }

  .informacion {
    grid-area: row-start 2 / col-start 1 / row-end 2 / col-end 1;
    padding: 3% 3% 3% 5%;
  }

  .formulario {
    grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    padding: 5%;
  }

  .inputsDatos{
    display: flex;
    width: 100%;
    gap: 1em;
    flex-direction: column;
  }

  .combo1{
    width: 100%;
  }
  .combo2{
    width: 100%;
  }

  .formulario input {
    height: 5px;
    padding: 2%;
  }

  .formulario textarea {
    padding: 2%;
  }

  .formulario label {
    font-size: 12px;
  }

  .titleForm {
    grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 1;
  }

  .inputNombre {
    grid-area: row-start 2 / col-start 1 / row-end 2 / col-end 1;
  }

  .inputEmail {
    grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 1;
  }

  .inputNumero {
    grid-area: row-start 4 / col-start 1 / row-end 4 / col-end 1;
  }

  .inputCiudad {
    grid-area: row-start 5 / col-start 1 / row-end 5 / col-end 1;
  }

  .textArea {
    grid-area: row-start 6 / col-start 1 / row-end 6 / col-end 1;
    height: 20vw;
    width: 100%;
  }

  .textArea textarea {
    height: 100%;
    width: auto;
  }

  .buttonSend {
    grid-area: row-start 7 / col-start 1 / row-end 7 / col-end 1;
    width: 20%;
    min-width: fit-content;
    height: 30px;
  }

  .documentacion {
    grid-area: row-start 4 / col-start 1 / row-end 4 / col-end 1;
    display: flex;
    flex-wrap: wrap;
    font-size: 70%;
    margin-bottom: 20px;
    padding: 0 20px 0 20px;
  }

  .documentacion p{
    margin: 2px;
  }
}
