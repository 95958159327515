.first-paragraph {
    grid-area: row-start 2 / col-start 1 /row-end 2 / col-end 1;
    background-color: gainsboro;
    width: 100%;
    height: fit-content;
    border: solid 1px black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.first-paragraph img {
    width: 70%;
    z-index: 1;
    margin-top: 5%;
}

.btnWeb {
    font-family: system-ui;
    font-weight: normal;
    text-align: center;
    padding: 5px 15px 5px 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    outline: none;
    border-radius: 10px;
    border: none;
    background-size: 120% auto;
    background-image: linear-gradient(315deg, black 0%, #dc2430 75%);
    font-size: 250%;
    margin: -2% 0 0 0;
}

.btnWeb a {
    text-decoration: none;
    color: #fff;
}

.btnWeb:hover {
    background-position: right center;
}

.btnWeb:active {
    top: 2px;
}

.first-paragraph-text {
    font-family: Inter;
    font-size: 220%;
    font-weight: 500;
    letter-spacing: -0.011em;
    text-align: center;
    width: 80%;
    margin-top: 5%;
}

@media screen and (max-width: 768px) {

    .first-paragraph {
        grid-area: row-start 2 / col-start 1 /row-end 2 / col-end 2;
    }

    .btnWeb {
        font-size: 4.5vw;
    }

    .first-paragraph-text {
        font-size: 4.5vw;
    }

}