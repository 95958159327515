.home {
    display: grid;
    grid-template-columns: repeat(2, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(4, [row-start] max-content [row-end]);
    gap: 1%;
}

@media screen and (max-width: 768px) {

    .home {
        display: grid;
        grid-template-columns: repeat(2, [col-start] 12fr [col-end]);
        grid-template-rows: repeat(5, [row-start] max-content [row-end]);
        gap: 1%;
    }

}