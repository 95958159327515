.bodyWelcome {
  display: flex;
  border: solid 1px black;
  border-radius: 10px;
  overflow: hidden;
  grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 2;
}

.navBar {
  display: grid;
  grid-template-columns: repeat(1, [col-start] 100% [col-end]);
  grid-template-rows: repeat(4, [row-start] auto[row-end]);
  height: 100%;
  width: 5%;
}

.navBar a {
  text-decoration: none;
  color: white;
}

.navBar p {
  transform: rotate(-90deg);
  margin: 0;
}

.square1 {
  grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 1;
  background-color: #1c3056;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square1 img {
  width: 90%;
}

.square2 {
  grid-area: row-start 2 / col-start 1 / row-end 2 / col-end 1;
  background-color: #00548c;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square3 {
  grid-area: row-start 3 / col-start 1 / row-end 3 / col-end 1;
  background-color: #0062a3;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square4 {
  grid-area: row-start 4 / col-start 1 / row-end 4 / col-end 1;
  background-color: #0071bd;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeView {
  background-color: gainsboro;
  height: auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
  padding: 5% 0 5% 0;
  position: relative;
}

.saltarVideo {
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 100%;
  width: fit-content;
  opacity: 0.2;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  transition: all 0.2s ease;
}

.button:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.buttonVideo {
  transition: all 0.2s ease;
}

.buttonVideo:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.display {
  display: none;
}

.saltarVideo:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.video-pos {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1000;
}

.bienvenidos {
  width: 55%;
  margin-bottom: 5%;
}

.logoCompleto {
  width: 20%;
  margin-bottom: 5%;
}

.sizeButtons {
  width: 40%;
  margin-top: 5%;
}

.btn1 {
  width: fit-content;
  border: none;
  border-radius: 10px;
}

.btn1 a {
  text-decoration: none;
  color: gainsboro;
}

.doubleButton {
  display: flex;
  justify-content: space-between;
}

.doubleButton button {
  border-radius: 10px;
  border: none;
}

.doubleButton a {
  text-decoration: none;
  color: gainsboro;
}

.welcomeView button {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 110%;
  padding: 1% 4% 1% 4%;
}

@media screen and (max-width: 768px) {
  .bodyWelcome {
    display: flex;
    flex-direction: column;
  }

  .navBar {
    display: grid;
    grid-template-columns: repeat(4, [col-start] 12fr [col-end]);
    grid-template-rows: repeat(1, [row-start] 100%[row-end]);
    height: 7%;
    width: 100%;
  }

  .navBar p {
    transform: rotate(0);
    font-size: 3vw;
    font-weight: 200;
  }

  .square1 {
    grid-area: row-start 1 / col-start 1 / row-end 1 / col-end 1;
  }

  .square1 img {
    width: 50%;
  }

  .square2 {
    grid-area: row-start 1 / col-start 2 / row-end 1 / col-end 2;
  }

  .square3 {
    grid-area: row-start 1 / col-start 3 / row-end 1 / col-end 3;
  }

  .square4 {
    grid-area: row-start 1 / col-start 4 / row-end 1 / col-end 4;
  }

  .welcomeView {
    background-color: gainsboro;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5%;
  }

  .video {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    object-fit: cover;
    height: 100%;
  }

  .video-pos {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    object-fit: cover;
    height: 100%;
  }

  .bienvenidos {
    width: 90%;
  }

  .logoCompleto {
    width: 50%;
  }

  .btn1 {
    width: fit-content;
  }

  .doubleButton {
    width: 90%;
    justify-content: space-around;
  }

  .doubleButton button {
    width: fit-content;
  }

  .welcomeView button {
    font-size: 3.5vw;
    background-color: rgba(0, 0, 0, 0.3);
    color: gainsboro;
    padding: 0 15px 0 15px;
  }
}
