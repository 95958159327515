.second-paragraph {
    grid-area: row-start 2 / col-start 2 /row-end 2 / col-end 2;
    background-color: gainsboro;
    width: 100%;
    border: solid 1px black;
    border-radius: 10px;
    background-image: url("../../imgs/logoCompletoColor.svg");
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    position: relative;
}

.second-paragraph img {
    width: 20%;
    position: absolute;
}

.img1 {
    top: 10%;
    left: 5%;
}

.img2 {
    top: 20%;
    left: 40%;

}

.img3 {
    top: 30%;
    left: 75%;

}

.btnWebSecond {
    font-family: system-ui;
    font-weight: normal;
    text-align: center;
    color: #fff;
    padding: 2px 30px 5px 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: absolute;
    outline: none;
    border-radius: 100px;
    border: none;
    background-size: 120% auto;
    background-image: linear-gradient(315deg, black 0%, #dc2430 75%);
    font-size: 1.5vw;
    top: 5%;
    opacity: 0.7;
    right: 2%;
}

@media screen and (max-width: 768px) {

    .second-paragraph {
        grid-area: row-start 3 / col-start 1 /row-end 3 / col-end 2;
        height: 85vw;
    }

    .btnWebSecond {
        font-size: 3.5vw;
    }

    .second-paragraph img {
        width: 25%;
        position: absolute;
    }

    .img1 {
        top: 8%;
        left: 4%;
    }

    .img2 {
        top: 24%;
        left: 38%;
    }

    .img3 {
        top: 40%;
        left: 70%;
    }

}